import axios, { AxiosRequestConfig } from 'axios';
import { PartialGuild, AuthStatus } from './types';

const CONFIG: AxiosRequestConfig = {
  withCredentials: true,
};

export const getAuthStatus = () => axios.get<AuthStatus>('https://camming.xyz/api/auth/status', CONFIG);
export const getAuthStatusLocalhost = () => axios.get<AuthStatus>('http://210.246.215.139:3001/auth/status', CONFIG);

export const postAuthLogout = () => axios.post('https://camming.xyz/api/auth/logout', {}, CONFIG);
export const postAuthLogoutLocalhost = () => axios.post('http://210.246.215.139:3001/auth/v2/logout', {}, CONFIG);

export const getMutualGuilds = () => axios.get<PartialGuild[]>('https://camming.xyz/api/discord/user/guilds', CONFIG);
export const getMutualGuildsLocalhost = () => axios.get<PartialGuild[]>('http://210.246.215.139:3001/discord/user/guilds', CONFIG);

export const checkToken = ( id: string, discordId: string, accessToken: string, refreshToken: string ) =>
axios.post<{ statusCode: string, message: string, error: string | undefined }>(
  'https://camming.xyz/api/auth/checkToken',
  { id, discordId, accessToken, refreshToken },
  CONFIG
);
export const checkTokenLocalhost = ( id: string, discordId: string, accessToken: string, refreshToken: string ) =>
axios.post<{ statusCode: string, message: string, error: string | undefined }>(
  'http://210.246.215.139:3001/auth/checkToken',
  { id, discordId, accessToken, refreshToken },
  CONFIG
);