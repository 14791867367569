import { BackgroundFixed, ContactPage1 } from "../utils/styles";

export const ContactPage = () => {
    const contact = Object.entries({
        'Email': { 'type': 'email', 'text': 'cammingbot@gmail.com, thirphathrphlphun@gmail.com' },
        'Discord': { 'type': 'link', 'text': 'https://discord.com/invite/brxPZB3Jzy' }
    });

    return (
        <div>
            <head>
                <title>Contact - Camming</title>
            </head>
            
            {/* Background */}
            <BackgroundFixed id="background"/>
            
            {/* Top */}
            <div style={{ marginTop: '70px', }} />
            
            {/* Part1 */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', }} >
                <ContactPage1>
                    {/* <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', }}>
                        <div>E-mail</div>
                        <div>Discord</div>
                    </div>
                    <div style={{ height: '100%', alignContent: 'center', }}><div style={{ borderRight: '1px #fff solid', height: '80%', }}/></div>
                    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', }}>
                        <div>cammingbot@gmail.com, thirphathrphlphun@gmail.com</div>
                        <a href="https://discord.com/invite/brxPZB3Jzy" style={{ color: '#fff', }}>https://discord.com/invite/brxPZB3Jzy</a>
                    </div> */}
                    <div style={{ display: 'flex', flexDirection: 'column', }}>
                        {contact.map(([key, value]) => (
                            <div key={key} style={{ display: "flex", alignItems: "center", borderRadius: 10, borderBottom: '3px solid #ff9999', padding: '15px 0', }} >
                                <div style={{ width: "15px", height: "100%", padding: '15px 0', }} />

                                <span style={{ fontWeight: "bold", width: '75px', textAlign: 'center', }}>{key}</span>
                                
                                <div style={{ width: "15px", height: "100%", padding: '15px 0', borderRadius: '0 10px 0 0', borderRight: '2px solid #ff9999', }} />
                                <div style={{ width: "15px", height: "100%", padding: '15px 0', }} />
                                
                                {value.type === "link" ? (
                                    <a href={value.text} target="_blank" style={{ color: '#fff', textAlign: 'center', flex: 1, }}>
                                        {value.text}
                                    </a>
                                ) : (
                                    <span style={{ color: '#fff', textAlign: 'center', flex: 1, }}>{value.text}</span>
                                )}

                                <div style={{ width: "15px", height: "100%", padding: '15px 0', }} />
                            </div>
                        ))}
                    </div>
                </ContactPage1>
            </div>
        </div>
    )
};
