import { useEffect, useContext, useState } from "react";
import { GuildContext, UserContext } from "../utils/contexts/Contexts";
import { BackgroundFixed, ChangePopup, Container, DashCategoryMenu, DashPageServerGeneralInfo, DashPageServerGeneralSettings, InputField, PopupStatusSaving, Select, Title } from "../utils/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { getAvatarURL, getIconURL } from "../utils/getURL";
import { GuildConfig, PartialGuild, localeLabels } from "../utils/types";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import { postAuthLogout, postAuthLogoutLocalhost } from "../utils/api";
import { TbArrowBigLeftFilled, TbArrowBigUpLines, TbDiamond, TbInfoCircle, TbLogout, TbPencil, TbSettings, TbStar } from "react-icons/tb";
import { BsStars } from "react-icons/bs";

export const DashboardPage = () => {
    const { guild, updateGuild } = useContext(GuildContext);
    const { user: profile } = useContext(UserContext);
    const dashboardType = window.location.pathname.startsWith('/dashboard/server') ? 'server'
                        : window.location.pathname.startsWith('/dashboard/user') ? 'user'
                        : null;
    const navigate = useNavigate();
    const [menuActive, setMenuActive] = useState(false);
    const [categoryMenu, setCategoryMenu] = useState<boolean[]>([true, true, true]);
    const [changePopup, setChangePopup] = useState<boolean | null>(null);
    const [popupStatusSaving, setPopupStatusSaving] = useState<boolean | null>(null);
    const [savingChangesLoading, setSavingChangesLoading] = useState(false);
    const location = useLocation();
  
    const toggleSideBar = (index: number) => {
        setCategoryMenu((prev) =>
            prev.map((item, i) => (i === index ? !item : item))
        );
    };
        
    const toggleMenu = () => { setMenuActive(!menuActive) };

    useEffect(() => {
        if(dashboardType == 'server' && !guild){ setTimeout(() => { navigate('/dashboard'); }, 1000); };
        if(dashboardType == 'user' && !profile){ setTimeout(() => { navigate('/dashboard'); }, 1000); };
        
        console.info(guild);

        if(window.location.pathname.endsWith('/')){ navigate(`${window.location.pathname.slice(0, -1)}`) };

        const subMenu = document.querySelector('#sub-menu');
        if(subMenu){
            const tags = subMenu.querySelectorAll('[data-tag]');
            tags.forEach((a) => {
                if(a instanceof HTMLElement){
                    const tag = a.getAttribute('data-tag');
                    const style = `font-size: 10px; margin-left: 5px; padding: 0px 8px; text-align: center; border-radius: 6px;`;
                    if(tag == 'new'){
                        a.textContent = 'NEW';
                        a.style.cssText = `background-color: #11700080; color: #26ff00; border: #26ff00 1px solid; ${style}`;
                    }else if(tag == 'update'){
                        a.textContent = 'UPDATE';
                        a.style.cssText = `background-color: #000f7080; color: #24b2ff; border: #24b2ff 1px solid; ${style}`;
                    }else if(tag == 'beta'){
                        a.textContent = 'BETA';
                        a.style.cssText = `background-color: #5c007080; color: #ec99ff; border: #ec99ff 1px solid; ${style}`;
                    }else if(tag == 'premium'){
                        a.textContent = 'PREMIUM';
                        a.style.cssText = `background-color: #67700080; color: #eeff38; border: #eeff38 1px solid; ${style}`;
                    };
                };
            });
        };
    }, []);

    useEffect(() => {
        document.querySelectorAll('#sub-menu').forEach(subMenu => {
            const items = subMenu.querySelectorAll('div[id]');
            items.forEach(e => {
                if(e instanceof HTMLElement && e.id == window.location.pathname.split('/')[3]){
                    e.style.backgroundColor = '#ff474780';
                }else if(e instanceof HTMLElement){
                    e.style.backgroundColor = '';
                };
            });
        });

        setChangePopup(null);
        setPopupStatusSaving(null);
    }, [location.pathname]);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if(changePopup){
                event.preventDefault();
                event.returnValue = "";
            };
        };
      
        window.addEventListener("beforeunload", handleBeforeUnload);
      
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [changePopup]);

    const navigateDashPage = (path: string | undefined) => {
        if(changePopup){
            const ePage = document.getElementById('page');
            const ePopup = document.getElementById('changePopup');
            if(ePage && ePopup){
                const ePopup_div = ePopup.querySelector("div");
                if(ePopup_div){
                    ePopup_div.style.backgroundColor = '#e73737';
                    setTimeout(() => {
                        ePopup_div.style.backgroundColor = '';
                    }, 500);
                };
                ePage.style.position = 'fixed';
                ePage.style.animation = 'shake 0.1s infinite';
                setTimeout(() => {
                    ePage.style.position = 'static';
                    ePage.style.animation = '';
                }, 500);
            };
        }else if(path){
            navigate(path);
        };
    };

    const saveGuildConfig = async (config: GuildConfig) => {
        try{
            const res = await axios.post<{config: any, statusText: string | null, error: string | null}>(
                window.location.hostname == 'camming.xyz' ? 'https://camming.xyz/api/discord/guilds/settings' : 'http://210.246.215.139:3001/discord/guilds/settings', config, { withCredentials: true, }
            );
            if(res.status == 200){
                return res.data;
            }else{
                throw res.data;
            }
        }catch(error){
            throw axios.isAxiosError(error) && error.response ? error.response.data : `SaveGuildConfigError: ${error}`;
        };
    };

    const buttonSaveChange = async (type: 'save' | 'cancel') => {
        if(location.pathname.startsWith('/dashboard/server/settings') && guild){
            const eLang = document.querySelector('select[name="language"]') as HTMLSelectElement;
            const ePrefix = document.querySelector('input[name="prefix"]') as HTMLInputElement;
            if(eLang && ePrefix){
                if(type == 'cancel'){
                    eLang.value = guild.data.preferred_locale || 'en_US';
                    ePrefix.value = guild.database?.prefix || '!';
                    setChangePopup(false);
                    return;
                }else if(type == 'save'){
                    let isSave = false;
                    (await Promise.all([eLang, ePrefix])).forEach((e) => {
                        if(!e.value){
                            e.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                            e.style.transition = '0.5s';
                            e.style.position = 'fixed';
                            e.style.animation = 'shake 0.1s infinite';
                            e.style.backgroundColor = 'red';
                            setTimeout(() => {
                                e.style.position = 'static';
                                e.style.animation = '';
                                e.style.backgroundColor = '';
                            }, 500);
                            return isSave = false;
                        }else if(e.value)return isSave = true;
                    });
                    if(isSave){
                        setPopupStatusSaving(true);
                        setSavingChangesLoading(true);
                        const guildConfig: GuildConfig = {
                            guildSettings: {
                                guildId: guild.id,
                                language: eLang.value,
                                prefix: ePrefix.value
                            }
                        };
                        try{
                            const saveStatus = await saveGuildConfig(guildConfig);
        
                            setChangePopup(false);
                            setSavingChangesLoading(false);
                            console.info(saveStatus);
                            setTimeout(() => {
                                const e = document.getElementById('statusSavingText');
                                if (e) e.textContent = saveStatus.statusText;
                                updateGuild({
                                    ...guild,
                                    database: {
                                        ...guild.database,
                                        prefix: saveStatus.config.prefix,
                                    },
                                    data: {
                                        ...(guild.data || {}),
                                        preferred_locale: saveStatus.config.language,
                                    },
                                });
                            }, 500);
                            setTimeout(() => {
                                setPopupStatusSaving(false);
                            }, 2000);
                        }catch(error){
                            setSavingChangesLoading(false);
                            console.error(error);
                            setTimeout(() => {
                                const e = document.getElementById('statusSavingText');
                                if (e) e.textContent = `${error}`;
                            }, 500);
                            setTimeout(() => {
                                setPopupStatusSaving(false);
                            }, 5000);
                        };
                    }
                };
            };
        };
    };

    const logout = () => {
        try{
            if(window.location.hostname == 'camming.xyz'){
                postAuthLogout()
                .then(() => {
                    window.location.pathname = '/dashboard';
                }).catch((error) => {
                    console.error(error);
                });
            }else{
                postAuthLogoutLocalhost()
                .then(() => {
                    window.location.pathname = '/dashboard';
                }).catch((error) => {
                    console.error(error);
                });
            };
        }catch(error){
            console.error(error);
        };
    };

    return dashboardType ? (
        <div>
            <head>
                <title>Dashboard - Camming</title>
            </head>

            {/* Background Fixed */}
            <BackgroundFixed id="background"/>
            
            {/* Top */}
            <div style={{ marginTop: '60px', }} />
            
            {/* Menu */}
            <div id="page" style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <div style={{ backgroundColor: '#ff666633', height: '85vh', width: '95vw', borderRadius: '30px', display: 'flex', flexDirection: 'row', }}>
                    <div style={{ position: 'absolute', zIndex: 2, width: '95%', }}>
                        <div onClick={toggleMenu} className={`dashMenuArrow ${menuActive ? 'show' : 'hide'}`}><img src="https://assets.camming.xyz/photos/icons/arrow-left.png" alt="show/hide" height={40}/></div>
                    </div>
                    <div className={`dashMenuBackDrop ${menuActive ? 'show' : 'hide'}`} onClick={() => setMenuActive(false)}/>
                    
                    <div id="category" className={`dashMenu ${menuActive ? 'show' : 'hide'}`}>
                        <div style={{ padding: '20px', }}>
                            <div id="back" onClick={() => navigateDashPage(`/dashboard`)} style={{ position: 'fixed', cursor: 'pointer', backgroundColor: 'rgba(255, 138, 138, 0.5)', borderRadius: 5, color: '#fff', textDecoration: 'none', padding: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'fit-content', }}>
                                <TbArrowBigLeftFilled style={{ marginRight: 5, }}/>back
                            </div>
                            
                            <div id="icon" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                {dashboardType == 'server' ? 
                                    guild?.icon
                                        ? <img src={getIconURL(guild)} height={80} />
                                        : <div style={{ width: '80px',  height: '80px', backgroundColor: 'rgba(255, 0, 0, 0.5)', alignContent: 'center', textAlign: 'center' }}>{guild?.name.slice(0, 2)}</div>
                                    : profile?.avatar
                                        ? <img src={getAvatarURL(profile)} height={80} />
                                        : <img src='https://cdn.discordapp.com/embed/avatars/0.png' height={80} />
                                }
                                <span style={{ fontSize: 25, fontWeight: 900, paddingTop: 10, alignContent: 'center', }}>{dashboardType == 'server' ? guild?.name : profile?.username}</span>
                            </div>
    
                            <div id="line" style={{ borderBottom: '1px #fff solid', margin: 20, }}/>

                            {dashboardType == 'server' ?
                            // server
                            <div>
                                <DashCategoryMenu id="general" isShow={categoryMenu[0]}>
                                    <div id="sidebar" onClick={() => toggleSideBar(0)}><div id="arrow">{">"}</div><div id="name">General</div></div>
                                    <div id="sub-menu">
                                        <div>
                                            <div id="main" onClick={() => {navigateDashPage('/dashboard/server/main'); setMenuActive(false);}}>
                                                <TbInfoCircle style={{ height: 20, width: 20, marginRight: 5, }} />
                                                Server info
                                                <div data-tag="new"/>
                                            </div>
                                            <div id="settings" onClick={() => {navigateDashPage('/dashboard/server/settings'); setMenuActive(false);}}>
                                                <TbSettings style={{ height: 20, width: 20, marginRight: 5, }} />
                                                Server Settings
                                                <div data-tag="new"/>
                                            </div>
                                            <div id="premium" onClick={() => {navigateDashPage('/dashboard/server/premium'); setMenuActive(false);}}>
                                                <BsStars style={{ height: 20, width: 20, marginRight: 5, }} />
                                                Get Premium
                                                <div data-tag="beta"/>
                                                <div data-tag="premium"/>
                                            </div>
                                            <div id="upgrade" onClick={() => {navigateDashPage('/dashboard/server/upgrade'); setMenuActive(false);}}>
                                                <TbArrowBigUpLines style={{ height: 20, width: 20, marginRight: 5, }} />
                                                Upgrade Bot
                                                <div data-tag="beta"/>
                                                <div data-tag="premium"/>
                                            </div>
                                        </div>
                                    </div>
                                </DashCategoryMenu>

                                <DashCategoryMenu id="Function settings" isShow={categoryMenu[1]}>
                                    <div id="sidebar" onClick={() => toggleSideBar(1)}><div id="arrow">{">"}</div><div id="name">Function Settings</div></div>
                                    <div id="sub-menu">
                                        <div>
                                            {/* <div id="none" onClick={() => {navigateDashPage('/dashboard/server/none'); setMenuActive(false);}}>
                                                <TbIcon style={{ height: 20, width: 20, marginRight: 5, }} />
                                                None
                                                <div data-tag="none"/>
                                            </div>
                                            <div id="none" onClick={() => {navigateDashPage('/dashboard/server/none'); setMenuActive(false);}}>
                                                <TbIcon style={{ height: 20, width: 20, marginRight: 5, }} />
                                                None
                                                <div data-tag="none"/>
                                            </div> */}
                                        </div>
                                    </div>
                                </DashCategoryMenu>

                                <DashCategoryMenu id="Other" isShow={categoryMenu[2]}>
                                    <div id="sidebar" onClick={() => toggleSideBar(2)}><div id="arrow">{">"}</div><div id="name">Other</div></div>
                                    <div id="sub-menu">
                                        <div>
                                            {/* <div id="none" onClick={() => {navigateDashPage('/dashboard/server/none'); setMenuActive(false);}}>
                                                <TbIcon style={{ height: 20, width: 20, marginRight: 5, }} />
                                                None
                                                <div data-tag="none"/>
                                            </div>
                                            <div id="none" onClick={() => {navigateDashPage('/dashboard/server/none'); setMenuActive(false);}}>
                                                <TbIcon style={{ height: 20, width: 20, marginRight: 5, }} />
                                                None
                                                <div data-tag="none"/>
                                            </div> */}
                                        </div>
                                    </div>
                                </DashCategoryMenu>
                            </div> :

                            // user
                            <div>
                                <DashCategoryMenu id="general" isShow={categoryMenu[0]}>
                                    <div id="sidebar" onClick={() => toggleSideBar(0)}><div id="arrow">{">"}</div><div id="name">General</div></div>
                                    <div id="sub-menu">
                                        <div>
                                            <div id="main" onClick={() => {navigateDashPage('/dashboard/user/main'); setMenuActive(false);}}>
                                                <TbInfoCircle style={{ height: 20, width: 20, marginRight: 5, }} />
                                                User info
                                                <div data-tag="new"/>
                                            </div>
                                            <div id="membership" onClick={() => {navigateDashPage('/dashboard/user/membership'); setMenuActive(false);}}>
                                                <TbDiamond style={{ height: 20, width: 20, marginRight: 5, }} />
                                                Membership
                                                <div data-tag="beta"/>
                                                <div data-tag="premium"/>
                                            </div>
                                            <div id="premium" onClick={() => {navigateDashPage('/dashboard/user/premium'); setMenuActive(false);}}>
                                                <BsStars style={{ height: 20, width: 20, marginRight: 5, }} />
                                                Manage Premium
                                                <div data-tag="beta"/>
                                                <div data-tag="premium"/>
                                            </div>
                                        </div>
                                    </div>
                                </DashCategoryMenu>

                                <DashCategoryMenu id="Other" isShow={categoryMenu[1]}>
                                    <div id="sidebar" onClick={() => toggleSideBar(1)}><div id="arrow">{">"}</div><div id="name">Other</div></div>
                                    <div id="sub-menu">
                                        <div>
                                            <div id="logout" onClick={() => {navigateDashPage('/dashboard/user/logout'); setMenuActive(false);}}>
                                                <TbLogout style={{ height: 20, width: 20, marginRight: 5, }} />
                                                Logout
                                                <div data-tag="beta"/>
                                            </div>
                                        </div>
                                    </div>
                                </DashCategoryMenu>
                            </div>
                            }
                        </div>
                    </div>

                    <div id="panel" style={{ width: '100%', overflow: 'auto', }}><div style={{ padding: '20px', }}>
                        {
                        // server
                        dashboardType == 'server' && guild ?
                            <div id="server">{
                                // general
                                window.location.pathname.startsWith('/dashboard/server/main') ?
                                <div>
                                    <div style={{ fontSize: '36px', fontWeight: 900, }}>Server Info</div>

                                    <div id="line" style={{ borderBottom: '1px #fff solid', margin: '20px 50px', }}/>

                                    <DashPageServerGeneralInfo>
                                        <div id="name">Name : {guild.name}</div>
                                        {guild.icon ? <div id="icon">Icon : <a href={getIconURL(guild)} target="_blank" style={{ color: '#8abbff', }}>{guild.icon}</a></div> : ''}
                                        <div id="id">Id : {guild.id}</div>
                                        <div id="ownerId">OwnerId : {guild.data.owner_id}</div>
                                        <div id="databaseId">DatabaseId : {guild.database.id || 'undefined'}</div>
                                        <div id="prefix">Prefix : {guild.database.prefix} <TbPencil onClick={() => navigate('/dashboard/server/settings/#prefix')} style={{ cursor: 'pointer', marginLeft: 5, height: 20, width: 20, }} /></div>
                                    </DashPageServerGeneralInfo>
                                </div> :

                                window.location.pathname.startsWith('/dashboard/server/settings') ?
                                <div>
                                    <div style={{ fontSize: '36px', fontWeight: 900, }}>Server Settings</div>

                                    <div id="line" style={{ borderBottom: '1px #fff solid', margin: '20px 50px', }}/>

                                    <DashPageServerGeneralSettings>
                                        <div id="language">
                                            Language
                                            <div>
                                                <Select name="language" defaultValue={guild.data.preferred_locale} onChange={() => setChangePopup(true)}>
                                                    {Object.entries(localeLabels).map(([code, label]) => (
                                                        <option key={code} value={code}>
                                                            {label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>

                                        <div id="line" style={{ borderBottom: '1px #ffffff80 solid', margin: '20px 50px', }}/>
                                        
                                        <div id="prefix">
                                            Prefix
                                            <div>
                                                <InputField name="prefix" minLength={1} maxLength={1} placeholder='Example: ?' defaultValue={guild.database.prefix} onChange={() => setChangePopup(true)} />
                                            </div>
                                        </div>
                                    </DashPageServerGeneralSettings>
                                </div> :

                                window.location.pathname.startsWith('/dashboard/server/premium') ?
                                <div>
                                    <div style={{ fontSize: '36px', fontWeight: 900, }}>Bot Premium</div>

                                    <div id="line" style={{ borderBottom: '1px #fff solid', margin: '20px 50px', }}/>

                                    <div>There are no subscription plans at this time.</div>
                                </div> :

                                window.location.pathname.startsWith('/dashboard/server/upgrade') ?
                                <div>
                                    <div style={{ fontSize: '36px', fontWeight: 900, }}>Upgrade Camming Bot</div>

                                    <div id="line" style={{ borderBottom: '1px #fff solid', margin: '20px 50px', }}/>

                                    <div>There are no subscription plans at this time.</div>
                                </div>
                                
                                
                                : window.location.pathname = '/dashboard'
                            }</div>

                        // user
                        : dashboardType == 'user' && profile ?
                            <div id="user">{
                                // general
                                window.location.pathname.startsWith('/dashboard/user/main') ?
                                <div>
                                    <div style={{ fontSize: '36px', fontWeight: 900, }}>User Info</div>

                                    <div id="line" style={{ borderBottom: '1px #fff solid', margin: '20px 50px', }}/>

                                    <DashPageServerGeneralInfo>
                                        <div id="name">Name : {profile.username}</div>
                                        {profile.avatar ? <div id="icon">Icon : <a href={getAvatarURL(profile)} target="_blank" style={{ color: '#8abbff', }}>{profile.avatar}</a></div> : ''}
                                        <div id="id">Id : {profile.id}</div>
                                    </DashPageServerGeneralInfo>
                                </div> :

                                window.location.pathname.startsWith('/dashboard/user/membership') ?
                                <div>
                                    <div style={{ fontSize: '36px', fontWeight: 900, }}>Membership</div>

                                    <div id="line" style={{ borderBottom: '1px #fff solid', margin: '20px 50px', }}/>

                                    <div>There are no subscription plans at this time.</div>
                                </div> :
                                
                                window.location.pathname.startsWith('/dashboard/user/premium') ?
                                <div>
                                    <div style={{ fontSize: '36px', fontWeight: 900, }}>Manage Premium</div>

                                    <div id="line" style={{ borderBottom: '1px #fff solid', margin: '20px 50px', }}/>

                                    <div>There are no subscription plans at this time.</div>
                                </div> :

                                // other
                                window.location.pathname.startsWith('/dashboard/user/logout') ?
                                <div>
                                    <div style={{ fontSize: '36px', fontWeight: 900, }}>Logout</div>

                                    <div id="line" style={{ borderBottom: '1px #fff solid', margin: '20px 50px', }}/>

                                    <div style={{ fontSize: 20, }}>Do you want to log out?</div>
                                    <button onClick={logout} style={{ padding: '10px 20px', border: '#e02424 2px solid', borderRadius: 5, backgroundColor: '#e34545', color: '#fff', cursor: 'pointer', fontWeight: 'bold', fontSize: 20, }}>Logout</button>
                                    <div style={{ marginTop: 50, }}>Note: If you have any problems logging out, please contact the developer.</div>
                                </div>

                                : window.location.pathname = '/dashboard'
                            }</div>

                                
                        : window.location.pathname = '/dashboard'
                        }

                        <ChangePopup id="changePopup" isShow={changePopup}>
                            <div>
                                <div>There are unsaved changes!</div>
                                <div>
                                    <div onClick={() => buttonSaveChange('cancel')}>Cancel</div>
                                    <div onClick={() => buttonSaveChange('save')}>Save Changes</div>
                                </div>
                            </div>
                        </ChangePopup>
                    </div></div>
                </div>

                <PopupStatusSaving id="statusSaving" isShow={popupStatusSaving}>
                    <div>
                        <div style={{ fontSize: 40, fontWeight: 900, }}>Saving changes...</div>
                        <div>Please wait a moment.</div>
                        {savingChangesLoading ? <PulseLoader color='#fff' /> : <div id="statusSavingText" style={{ fontSize: 20, }}>Connecting to server...</div>}
                    </div>
                </PopupStatusSaving>
            </div>
        </div>
    ) : (
        <div><BackgroundFixed id="background"/><div style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "x-large", }}>Invalid Dashboard Context</div></div>
    );
};