import { createContext } from "react";
import { PartialGuild, User } from "../types";

type GuildContextType = {
    guild?: PartialGuild;
    updateGuild: (guild: PartialGuild) => void;
};
export const GuildContext = createContext<GuildContextType>({
    updateGuild: () => {},
});

type UserContextType = {
    user?: User;
    userError?: any;
    updateUser: (user: User) => void;
    updateUserError: (userError: any) => void;
};
export const UserContext = createContext<UserContextType>({
    user: undefined,
    userError: undefined,
    updateUser: () => {},
    updateUserError: () => {}  
});

type MapGuildContextType = {
    guilds?: PartialGuild[];
    loadingGuilds: boolean;
    errorGuilds?: any;
    updateGuilds: (guild: PartialGuild[]) => void;
    updateLoadingGuilds: (loadingGuilds: boolean) => void;
    updateErrorGuilds: (errorGuilds: any) => void;
};
export const MapGuildsContext = createContext<MapGuildContextType>({
    guilds: undefined,
    loadingGuilds: true,
    errorGuilds: undefined,
    updateGuilds: () => {},
    updateLoadingGuilds: () => {},
    updateErrorGuilds: () => {}
});