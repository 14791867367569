import { BackgroundFixed } from "../utils/styles";

export const PageNotFound = () => {
    return (
        <div>
            <head>
                <title>Not Found - Camming</title>
            </head>
            <BackgroundFixed id="background"/>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh", width: "100vw", margin: 0, overflowX: "hidden", textAlign: "center", }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", }}>
                    <p style={{ fontSize: "80px", color: "#d99a9a", margin: 0, }}>404</p>
                    <p style={{ fontSize: "25px", margin: 0, }}>Page not found: {window.location.pathname}</p>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "70px", width: "100%", background: "rgb(46, 46, 46)", }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "85%", }}>
                        <div style={{ color: "#b5b5b5", }}>
                            © 2024 Camming All Rights Reserved.
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", minWidth: "25%", }}>
                            <a href="" style={{ color: "#b5b5b5", textDecoration: "none", }}>Privacy Policy</a>
                            <a href="" style={{ color: "#b5b5b5", textDecoration: "none", }}>Terms &amp; Conditions</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};