import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { MenuPage } from './pages/MenuPage';
import { DashboardPage } from './pages/DashboardPage';
import { GuildPrefixPage } from './pages/GuildPrefixPage';
import { WelcomeMessagePage } from './pages/WelcomeMessagePage';
import { GuildContext, MapGuildsContext, UserContext } from './utils/contexts/Contexts';
import { AppBar } from './components/AppBar';
import { Spinner } from './components/Spinner';
import { Loading, LoadingEnd } from './pages/LoadingPage';
import axios from 'axios';
import { HomePage } from './pages/HomePage';
import { wait } from '@testing-library/user-event/dist/utils';
import { PartialGuild, User } from './utils/types';
import { PageNotFound } from './pages/PageNotFound';
import { InvitePage } from './pages/InvitePage';
import { ContactPage } from './pages/ContactPage';
import { DonatePage } from './pages/DonatePage';

export const hideLoading = () => {
  setTimeout(() => {
    if (document.readyState === 'complete') {
      const preLoaderBar = document.getElementById('PreLoaderBar');
      if (preLoaderBar) {
        preLoaderBar.style.display = 'none';
      }
    } else {
      const preLoaderBar = document.getElementById('PreLoaderBar');
      if (preLoaderBar) {
        preLoaderBar.style.display = 'block';
      }
    }
  }, 2000);
};

function App() {
  const [guild, setGuild] = useState<PartialGuild>();
  const updateGuild = (guild: PartialGuild) => setGuild(guild);

  const [user, setUser] = useState<User>();
  const [userError, setUserError] = useState();
  const updateUser = (user: User) => setUser(user);
  const updateUserError = (error: any) => setUserError(error);

  const [guilds, setGuilds] = useState<PartialGuild[]>();
  const [errorGuilds, setErrorGuilds] = useState();
  const [loadingGuilds, setLoadingGuilds] = useState<boolean>(true);
  const updateGuilds = (guilds: PartialGuild[]) => setGuilds(guilds);
  const updateLoadingGuilds = (loadingGuilds: boolean) => setLoadingGuilds(loadingGuilds);
  const updateErrorGuilds = (errorGuilds: any) => setErrorGuilds(errorGuilds);

  const location = useLocation();
  const navigate = useNavigate();

  // if (loading) return <Spinner children={<Loading />} />;

  // return <LoginPage />;

  if(window.location.pathname == '/discord'){
    window.location.href = 'https://discord.com/invite/brxPZB3Jzy';
  };if(window.location.pathname == '/invite'){
    window.location.href = 'https://discord.com/oauth2/authorize?client_id=1210178730836893719';
  };
  
  useEffect(() => {
    hideLoading();

    window.addEventListener('load', hideLoading);

    return () => {
      window.removeEventListener('load', hideLoading);
    };
  }, [location.pathname]);

  useEffect(() => {
    const targetId = location.hash.replace('#', '');
    const element = document.getElementById(targetId);
    if(element){
      let times = 0;
      const interval = setInterval(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        if(times < 4){
            element.style.transition = '0.5s';
            element.style.padding = times % 2 === 0 ? '0px 15px': '';
            element.style.borderRadius = times % 2 === 0 ? '10px' : '';
            element.style.backgroundColor = times % 2 === 0 ? '#ffa8a866' : '';
            times++;
        }else{
            clearInterval(interval);
        };
      }, 200);
    };
  }, [location.hash]);

  return (
    <UserContext.Provider value={{ user, userError, updateUser, updateUserError }}>
    <MapGuildsContext.Provider value={{ guilds, loadingGuilds, errorGuilds, updateGuilds, updateLoadingGuilds, updateErrorGuilds }}>
    <GuildContext.Provider value={{ guild, updateGuild }}>
    {/* <Routes>
      <Route path='*' element={<LoadingEnd />} />
    </Routes> */}
    {/* {user && !error ? (
      <> */}
      
      <div><AppBar /><div className="preLoaderBar backdrop-blur" id="PreLoaderBar"><div className="progress"><div className="indeterminate"></div></div></div></div>

      <Routes>
        <Route path='/' element={<HomePage />} />
        {/* <Route path='/login' element={<Navigate to="/api/auth/login" />} /> */}
        <Route path='/contact' element={<ContactPage />} />
          <Route path='/dashboard/invite' element={<InvitePage />} />
          <Route path='/dashboard' element={<MenuPage />} />
          <Route path='/dashboard/*' element={<DashboardPage />} />
        <Route path='/donate' element={<DonatePage />} />
        <Route path='/logout' element={<Navigate to='/dashboard/user/logout'/>} />
        {/* <Route path='/dashboard/prefix' element={<GuildPrefixPage />} />
        <Route path='/dashboard/welcome' element={<WelcomeMessagePage />} /> */}
      {/* </Routes>
      </>
    ) : (
      <Routes> */}
        {/* <Route path='/' element={<LoginPage />} /> */}
        {/* <Route path='/login' element={<LoginPage />} /> */}
        {/* <Route path='/guilds' element={<Navigate to="/login" />} />
        <Route path='/dashboard/*' element={<Navigate to="/login" />} /> */}
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    {/* )} */}
    </GuildContext.Provider>
    </MapGuildsContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
