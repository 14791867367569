import { redirect, useNavigate } from "react-router-dom";
import { mockGuilds } from "../__mocks__/guilds";
import { useContext, useState, useEffect } from "react";
import { GuildContext, MapGuildsContext, UserContext } from "../utils/contexts/Contexts";
import { GuildMenuItem } from "../components/GuildMenuItem";
import { Container, GuildMenuItemStyle, GuildMenuItemStyleLoading, NotFoundImg, NotFoundText, ShowPopupLoadingErrorBox, ShowPopupLoadingError, BackgroundFixed, GuildMenuCreateData, UserMenuItem } from "../utils/styles";
import { useFetchUser } from "../utils/hooks/useFetchUser";
import { useFetchGuilds } from "../utils/hooks/useFetchGuilds";
import axios from "axios";
import { PartialGuild, AuthStatus } from "../utils/types";
import { getMutualGuilds, getAuthStatus, getAuthStatusLocalhost, getMutualGuildsLocalhost } from "../utils/api";
import { PulseLoader } from "react-spinners";
import { getAvatarURL } from "../utils/getURL";
import { TbRefresh } from "react-icons/tb";
// import { wait } from "@testing-library/user-event/dist/utils";

export const MenuPage = () => {
    // const [loading, setLoading] = useState(true);
    // const [loadingGuilds, setLoadingGuilds] = useState(true);
    // const { user, errorUser, loadingUser } = useFetchUser();
    // const { guilds, errorGuilds, loadingGuilds } = useFetchGuilds();
    // const [error, setError] = useState('');
    const [showPopupLoadingError, setShowPopupLoadingError] = useState(false);
    const [internetError, setInternetError] = useState(false);
    const [showPopupCreateDataInDatabase, setShowPopupCreateDataInDatabase] = useState(false);
    const [createDataInDatabaseLoading, setCreateDataInDatabaseLoading] = useState(false);

    const navigate = useNavigate();
    const { updateGuild } = useContext(GuildContext);
    const { user, userError } = useContext(UserContext);
    const { guilds, loadingGuilds, errorGuilds, updateGuilds, updateLoadingGuilds, updateErrorGuilds } = useContext(MapGuildsContext);

    const handleClick = async (guild: PartialGuild) => {
        updateGuild(guild);
        if(guild.bot_here && guild.database){
            navigate(`/dashboard/server/main`);
        }else if(guild.bot_here && !guild.database){
            setShowPopupCreateDataInDatabase(true);
            setCreateDataInDatabaseLoading(true);
            const CreateGuild = async () => {
                if(window.location.hostname == 'camming.xyz'){
                    try{
                        const res = await fetch(`https://camming.xyz/api/discord/createGuild/${guild.id}`, {
                            method: 'post'
                        });
                        if(res.ok){
                            return 'Succeed';
                        }else{
                            return `Error ${res.status}`;
                        }
                    }catch(error: any){
                        console.error(error);
                        return `Error: ${error.name}`;
                    };
                }else{
                    try{
                        const res = await fetch(`http://210.246.215.139:3001/discord/createGuild/${guild.id}`, {
                            method: 'post'
                        });
                        if(res.ok){
                            return 'Succeed';
                        }else{
                            return `Error ${res.status}`;
                        }
                    }catch(error: any){
                        console.error(error);
                        return `Error: ${error.name}`;
                    };
                }
            };
            const createGuild = await CreateGuild();
            setTimeout(() => {
                setCreateDataInDatabaseLoading(false);
                setTimeout(() => {
                    const e = document.getElementById('createStatus');
                    if(e){e.textContent = createGuild};
                    setTimeout(() => { window.location.reload() }, 3000);
                }, 1000);
            }, 1000);
        }else{
            navigate(`/dashboard/invite`);
        }
        // window.location.href = `/dashboard/${guild.id}/main`;
    };
    function FetchGuilds() {
        updateLoadingGuilds(true);
        if (window.location.hostname == 'camming.xyz') {
            getMutualGuilds()
            .then(({ data }) => {
                console.info('Guilds:', data);
                updateGuilds(data);
                updateLoadingGuilds(false);
            })
            .catch(error => {
                setTimeout(() => {
                    getMutualGuilds()
                    .then(({ data }) => {
                        console.info('Guilds:', data);
                        updateGuilds(data);
                        updateLoadingGuilds(false);
                    })
                    .catch(error => {
                        updateLoadingGuilds(false);
                        console.error(error);
                        updateErrorGuilds(`${error}`);
                    })
                }, 5000);
            })
        } else if (window.location.hostname != 'camming.xyz')  {
            getMutualGuildsLocalhost()
            .then(({ data }) => {
                console.info('Guilds:', data);
                updateGuilds(data);
                updateLoadingGuilds(false);
            })
            .catch(error => {
                setTimeout(() => {
                    getMutualGuildsLocalhost()
                    .then(({ data }) => {
                        console.info('Guilds:', data);
                        updateGuilds(data);
                        updateLoadingGuilds(false);
                    })
                    .catch(error => {
                        updateLoadingGuilds(false);
                        console.error(error);
                        updateErrorGuilds(`${error}`);
                    })
                }, 5000);
            })
        }
    };
    if (window.location.href == 'https://camming.xyz/dashboard/'){window.location.href = 'https://camming.xyz/dashboard';};

    useEffect(() => {
        // if(loadingUser || loadingGuilds){
        if(!user || loadingGuilds){
            const timeShowPopupLoadingError = setTimeout(() => {
                setShowPopupLoadingError(true);
            }, 3000);
            return () => clearTimeout(timeShowPopupLoadingError);
        }else{setShowPopupLoadingError(false)};
    }, [loadingGuilds]);
    
    //   const handleClick = (guild: PartialGuild) => {
    //     history.push(`/guilds/${guildId}`);
    //   };

    if(!user && !userError){
    return (
        <div>
            <head>
                <title>Loading</title>
            </head>
            <BackgroundFixed id="background"/>
            <div style={{ marginTop: 70 }}>
                <Container><h2>Loading User<span className='loadingDot'></span></h2></Container>
                <Container>
                    <GuildMenuItemStyleLoading><div/><p/></GuildMenuItemStyleLoading>
                    <GuildMenuItemStyleLoading><div/><p/></GuildMenuItemStyleLoading>
                    <GuildMenuItemStyleLoading><div/><p/></GuildMenuItemStyleLoading>
                    <GuildMenuItemStyleLoading><div/><p/></GuildMenuItemStyleLoading>
                </Container>
                {showPopupLoadingError && (
                    <ShowPopupLoadingErrorBox>
                        <ShowPopupLoadingError>
                        If there is a problem obtaining user information, please contact the system administrator: <a href="https://discord.gg/brxPZB3Jzy" style={{ color: '#ff8f8f', textDecorationColor: '#ffbdbd' }} >Discord</a>
                        </ShowPopupLoadingError>
                    </ShowPopupLoadingErrorBox>
                )}
            </div>
        </div>
    );
    };

    if(user){
    if(!guilds){FetchGuilds()}; return (
        <div>
            <head><title>Dashboard - Camming</title></head>
            <BackgroundFixed id="background"/>

            <Container style={{ marginTop: 70 }}>
                <h2>User</h2>
                <UserMenuItem onClick={() => navigate('/dashboard/user/main')}>
                    {user.avatar && (
                        <img src={getAvatarURL(user)} alt={user.username} width={40} height={40} />
                    )}
                    {!user.avatar && (
                        <img src={'https://cdn.discordapp.com/embed/avatars/0.png'} alt={user.username} width={40} height={40} />
                    )}
                    <p style={{ display: 'flex', justifyContent: 'right', height: '25px', width: '250px', marginTop: '16px', marginBottom: '16px' }}>{user.username}</p>
                </UserMenuItem>

                {loadingGuilds ? 
                <div>
                    <h2>Loading Guilds<span className='loadingDot'></span></h2>
                    <div>
                        <GuildMenuItemStyleLoading><div/><p/></GuildMenuItemStyleLoading>
                        <GuildMenuItemStyleLoading><div/><p/></GuildMenuItemStyleLoading>
                        <GuildMenuItemStyleLoading><div/><p/></GuildMenuItemStyleLoading>
                        <GuildMenuItemStyleLoading><div/><p/></GuildMenuItemStyleLoading>
                    </div>
                    {showPopupLoadingError && (
                        <ShowPopupLoadingErrorBox>
                            <ShowPopupLoadingError>
                            If there is a problem obtaining user information, please contact the system administrator: <a href="https://discord.gg/brxPZB3Jzy" style={{ color: '#ff8f8f', textDecorationColor: '#ffbdbd' }} >Discord</a>
                            </ShowPopupLoadingError>
                        </ShowPopupLoadingErrorBox>
                    )}
                </div> : guilds ? 
                <div>
                    <GuildMenuCreateData isShow={showPopupCreateDataInDatabase}>
                        <div>
                            <div style={{ fontSize: 30, fontWeight: 900, }}>Setting up server information in database</div>
                            <p>Please wait a moment.</p>
                            {createDataInDatabaseLoading ? <PulseLoader color='#fff' /> : <div id="createStatus">Loading...</div>}
                        </div>
                    </GuildMenuCreateData>
                
                    <h2 style={{ display: 'flex', alignItems: 'center', }}>
                        Guilds
                        <TbRefresh style={{ marginLeft: 10, color: 'rgb(255, 100, 100)', cursor: 'pointer', }} onClick={()=>FetchGuilds()}/>
                    </h2>
                    {/* <h2 style={{ marginTop: 0, }}>Select a Server</h2> */}
                    {guilds.map((guild) => (
                        <div key={guild.id}>
                            <div onClick={() => handleClick(guild)}>
                                <GuildMenuItem guild={guild} />
                            </div>
                        </div>
                    ))}
                </div> : <div>The server you are administrator was not found.</div>}
                
            </Container>
        </div>
    );
    };
    
    if(userError){
        return (
            <div>
                <head>
                    <title>Not Found - {userError}</title>
                </head>
                <BackgroundFixed id="background"/>
                <div style={{ height: '100vh', width: '100vw' }}>
                    <NotFoundImg>
                        <img src={userError == '403' ? 'https://assets.camming.xyz/photos/not-found/not%20found%20403.png' : 'https://assets.camming.xyz/photos/not-found/not%20found1.png'} alt='Not Found' width={130} height={130} />
                    </NotFoundImg>
                    <NotFoundText style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <h1>Please login</h1>
                        <a onClick={() => {
                                if (window.location.hostname == 'camming.xyz') {window.location.href = 'https://camming.xyz/api/auth/login'}
                                else {window.location.href = 'http://210.246.215.139:3001/auth/v2/login'}
                            }}
                            style={{ color: '#fff', textDecoration: 'none', backgroundColor: '#4d7cff', padding: '10px 40px', borderRadius: 5, marginTop: 10, cursor: 'pointer', }}>Login</a>
                        <p style={{ margin: '0' }}>or</p>
                        <a href='/' style={{ color: '#fff', textDecoration: 'none', backgroundColor: '#ff3333', padding: 10, borderRadius: 5, }}>Back to Home Page</a>
                    </NotFoundText>
                    {showPopupLoadingError && (
                        <ShowPopupLoadingErrorBox>
                            <ShowPopupLoadingError>
                            If there is a problem obtaining user information, please contact the system administrator: <a href="https://discord.gg/brxPZB3Jzy" style={{ color: '#ff8f8f', textDecorationColor: '#ffbdbd' }} >Discord</a>
                            </ShowPopupLoadingError>
                        </ShowPopupLoadingErrorBox>
                    )}
                </div>
            </div>
        );
    }else
    if(errorGuilds){
    return (
        <div>
            <head>
                <title>Not Found - {errorGuilds}</title>
            </head>
            <BackgroundFixed id="background"/>
            <div style={{ height: '100vh', width: '100vw' }}>
                <NotFoundImg>
                    <img src={errorGuilds == '403' ? 'https://assets.camming.xyz/photos/not-found/not%20found%20403.png' : 'https://assets.camming.xyz/photos/not-found/not%20found1.png'} alt='Not Found' width={130} height={130} />
                </NotFoundImg>
                <NotFoundText style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <h2 style={{ margin: '0' }}>Not Found</h2>
                    <p style={{ margin: '0' }}>{errorGuilds}</p>
                    <a onClick={() => {
                            if (window.location.hostname == 'camming.xyz') {window.location.href = 'https://camming.xyz/api/auth/login'}
                            else {window.location.href = 'http://210.246.215.139:3001/auth/v2/login'}
                        }}
                        style={{ color: '#fff', textDecoration: 'none', backgroundColor: '#4d7cff', padding: '10px 40px', borderRadius: 5, marginTop: 10, cursor: 'pointer', }}>Login</a>
                    <p style={{ margin: '0' }}>or</p>
                    <a href='/' style={{ color: '#fff', textDecoration: 'none', backgroundColor: '#ff3333', padding: 10, borderRadius: 5, }}>Back to Home Page</a>
                </NotFoundText>
                {showPopupLoadingError && (
                    <ShowPopupLoadingErrorBox>
                        <ShowPopupLoadingError>
                        If there is a problem obtaining user information, please contact the system administrator: <a href="https://discord.gg/brxPZB3Jzy" style={{ color: '#ff8f8f', textDecorationColor: '#ffbdbd' }} >Discord</a>
                        </ShowPopupLoadingError>
                    </ShowPopupLoadingErrorBox>
                )}
            </div>
        </div>
    );
    };

    return (
        <div>
            <head>
                <title>Not Found - Camming</title>
            </head>
            <BackgroundFixed id="background"/>
            <div style={{ height: '100vh', width: '100vw' }}>
                <NotFoundImg>
                    <img src='https://assets.camming.xyz/photos/not-found/not%20found1.png' alt='Not Found' width={130} height={130} />
                </NotFoundImg>
                <NotFoundText style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <h2 style={{ margin: '0' }}>Not Found</h2>
                    <a onClick={() => {
                            if (window.location.hostname == 'camming.xyz') {window.location.href = 'https://camming.xyz/api/auth/login'}
                            else {window.location.href = 'http://210.246.215.139:3001/auth/v2/login'}
                        }}
                        style={{ color: '#fff', textDecoration: 'none', backgroundColor: '#4d7cff', padding: '10px 40px', borderRadius: 5, marginTop: 10, cursor: 'pointer', }}>Login</a>
                    <p style={{ margin: '0' }}>or</p>
                    <a href='/' style={{ color: '#fff', textDecoration: 'none', backgroundColor: '#ff3333', padding: 10, borderRadius: 5, }}>Back to Home Page</a>
                </NotFoundText>
                {showPopupLoadingError && (
                    <ShowPopupLoadingErrorBox>
                        <ShowPopupLoadingError>
                        If there is a problem obtaining user information, please contact the system administrator: <a href="https://discord.gg/brxPZB3Jzy" style={{ color: '#ff8f8f', textDecorationColor: '#ffbdbd' }} >Discord</a>
                        </ShowPopupLoadingError>
                    </ShowPopupLoadingErrorBox>
                )}
            </div>
        </div>
    );
};