import { APIGuild, Locale } from "discord.js";

export type User = {
    id: string;
    username: string;
    global_name: string;
    avatar: string;
    banner?: string;
    locale?: string;
    discriminator: string;
    public_flags: string;
    flags: string;
    accent_color: string;
    avatar_decoration_data: string;
    banner_color: string;
    clan: string;
}

export type UserStatus = {
    status: string;
    activities: [
        {
            name: string,
            type: number,
            details: string,
            state: string,
            timestamps: {
                start: string;
                end: string | null;
            };
        }
    ];
}

export type UserData = {
    status: string;
    activities: [
        {
            name: string,
            type: number,
            details: string,
            state: string,
            timestamps: {
                start: string;
                end: string | null;
            };
        }
    ];
    id: string;
    username: string;
    global_name: string;
    avatar: string;
    banner?: string;
    locale?: string;
    discriminator: string;
    public_flags: string;
    flags: string;
    accent_color: string;
    avatar_decoration_data: string;
    banner_color: string;
    clan: string;
}

export type PartialGuild = {
    id: string;
    name: string;
    icon: string;
    owner: boolean;
    permissions: string;
    features: string[];
    bot_here: boolean;
    data: APIGuild;
    database: {
        id: string,
        guildId: string,
        prefix: string,
        auditLogSettings: string | null
    };
};

export type AuthStatus = {
    id: string;
    discordId: string;
    accessToken: string;
    refreshToken: string;
}

export type AuditLogSettings = {
    member_joined: string | null;
    member_leaved: string | null;
    member_banned: string | null;
    member_unbanned: string | null;
    member_kicked: string | null;
    member_role_updated: string | null;
    message_deleted: string | null;
    message_bulk_deleted: string | null;
    message_edited: string | null;
    channel_created: string | null;
    channel_updated: string | null;
    channel_deleted: string | null;
    thread_created: string | null;
    thread_updated: string | null;
    thread_deleted: string | null;
    guild_updated: string | null;
    role_created: string | null;
    role_updated: string | null;
    role_deleted: string | null;
    moderation_command_used: string | null;
    [key: string]: string | null;
};

export const localeLabels: Record<Locale, string> = {
    'en-US': 'English (US)',
    'en-GB': 'English (UK)',
    'bg': 'Български', // Bulgarian
    'zh-CN': '简体中文', // Chinese (Simplified)
    'zh-TW': '繁體中文', // Chinese (Traditional)
    'hr': 'Hrvatski', // Croatian
    'cs': 'Čeština', // Czech
    'da': 'Dansk', // Danish
    'nl': 'Nederlands', // Dutch
    'fi': 'Suomi', // Finnish
    'fr': 'Français', // French
    'de': 'Deutsch', // German
    'el': 'Ελληνικά', // Greek
    'hi': 'हिन्दी', // Hindi
    'hu': 'Magyar', // Hungarian
    'it': 'Italiano', // Italian
    'ja': '日本語', // Japanese
    'ko': '한국어', // Korean
    'lt': 'Lietuvių', // Lithuanian
    'no': 'Norsk', // Norwegian
    'pl': 'Polski', // Polish
    'pt-BR': 'Português (Brasil)', // Portuguese (Brazil)
    'ro': 'Română', // Romanian
    'ru': 'Русский', // Russian
    'es-ES': 'Español (España)', // Spanish (Spain)
    'es-419': 'Español (Latinoamérica)', // Spanish (Latin America)
    'sv-SE': 'Svenska', // Swedish
    'th': 'ไทย', // Thai
    'tr': 'Türkçe', // Turkish
    'uk': 'Українська', // Ukrainian
    'vi': 'Tiếng Việt', // Vietnamese
    'id': 'Bahasa Indonesia', // Indonesian
};

export type GuildConfig = {
    guildSettings: {
        guildId: string;
        language: string;
        prefix: string;
    } | undefined;
};