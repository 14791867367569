import { AppBarStyle, TopBarShortCutLink } from "../utils/styles"
import axios from "axios";
import { User, AuthStatus } from "../utils/types";
import { createContext, useContext, useEffect, useState } from "react";
import { getAvatarURL } from "../utils/getURL";
import { GuildContext, UserContext } from "../utils/contexts/Contexts";
import { useFetchUser } from "../utils/hooks/useFetchUser";
import { FaBars, FaDonate, FaHome } from "react-icons/fa";
import { TbLayoutDashboardFilled, TbLogout } from "react-icons/tb";
import { BsSlashSquareFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { getAuthStatus, getAuthStatusLocalhost } from "../utils/api";
import { useNavigate } from "react-router-dom";
import { hideLoading } from "../App";

// type UserContextType = {
//     user?: AuthStatus;
//     updateUser: (user: AuthStatus) => void;
// }
// type ProfileContextType = {
//     profile?: User;
//     updateProfile: (profile: User) => void;
// }
// export const AuthStatusContext = createContext<UserContextType>({
//     updateUser: () => {},
// });
// export const ProfileContext = createContext<ProfileContextType>({
//     updateProfile: () => {},
// });


export const AppBar = () => {
    const { guild } = useContext(GuildContext);
    const { user: profile, userError, updateUser, updateUserError } = useContext(UserContext);
    const { user, errorUser, loadingUser } = useFetchUser();
    // const [user, setUser] = useState<AuthStatus>();
    // const [errorUser, setError] = useState('');
    // const [profile, setProfile] = useState<User>();
    const [menuActive, setMenuActive] = useState(false);
    const navigate = useNavigate();

    // console.log(guild);
    
    useEffect(() => {
        updateUserError(undefined);
        if(user){
            try{
                axios.get<User>('https://discord.com/api/users/@me', {
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`,
                    }
                }).then(({data}) => {
                    // setProfile(data);
                    // console.log(`UserData: ${data}`);
                    updateUser(data);
                    updateUserError(undefined);
                }).catch((err) => {
                    updateUserError(err);
                    console.error(`UserError: ${err}`);
                });
            }catch(err){
                console.error(`UserError: ${err}`);
            };
        }else{
            updateUserError('User not found')
        };
        
        hideLoading();
    
        window.addEventListener('load', hideLoading);
    
        return () => {
            window.removeEventListener('load', hideLoading);
        };
    }, [user]);

    useEffect(() => {
        const elements = document.querySelectorAll<HTMLElement>("#menuBurgerBar");

        elements.forEach((element) => {
            element.addEventListener("mouseenter", () => {
                // console.log(`Mouse entered: ${element.id}`);
                setMenuActive(true);
            });
            element.addEventListener("mouseleave", () => {
                // console.log(`Mouse left: ${element.id}`);
                setMenuActive(false);
            });
        });
    }, []);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    const mapShortCut = Object.values({
        0: [
            { name: 'Home', path: 'home', icon: <FaHome />, onlyHamburger: false },
            { name: 'Dashboard', path: 'dashboard', icon: <TbLayoutDashboardFilled />, onlyHamburger: false },
            { name: 'Commands', path: 'commands', icon: <BsSlashSquareFill />, onlyHamburger: false },
        ],
        1: [
            { name: 'Contact', path: 'contact', icon: <MdEmail />, onlyHamburger: false, }
        ],
        2: [
            { name: 'Donate', path: 'donate', icon: <FaDonate />, onlyHamburger: true },
            profile ? { name: 'Logout', path: 'logout', icon: <TbLogout />, onlyHamburger: true } : {},
        ],
    });
    
    // if (profile) {
    //     return (
    //     <div>
    //         {/* <AppBarStyle>
    //             <p>{profile.global_name}</p>
    //             {profile.avatar && <img src={getAvatarURL(profile)} alt={profile.username} height={45} width={45} style={{ borderRadius: '50%' }} />}
    //             {!profile.avatar && <img src='https://cdn.discordapp.com/embed/avatars/0.png' alt={profile.username} height={45} width={45} style={{ borderRadius: '50%' }} />}
    //         </AppBarStyle> */}

    //         <div style={{ position: "fixed", display: "flex", alignItems: "center", justifyContent: "space-between", background: "#171717e0", boxShadow: "0 2px 10px 0 rgba(23, 23, 23, 0.88)", top: 0, zIndex: 100, width: "100%", height: 60, maxHeight: "40%", }} >
    //         <div style={{ display: "flex", justifyContent: "space-between", marginLeft: 20, marginRight: 20, width: "100%", }} >
    //             <a href="/" style={{ color: "white", textDecoration: "none", width: 150, fontSize: 20, display: "flex", alignItems: "center", }}>
    //                 <img src="/assets/images/logo2.png" alt="logo" width={40} style={{ borderRadius: 5, marginRight: 15, }} />
    //                 <span>Camming</span>
    //             </a>
    //             <div className={`topBarShortCutLink ${menuActive ? 'mobile-active' : ''}`} style={{ width: "40%", justifyContent: "space-between", alignItems: "center", animation: menuActive ? 'mobile-active-show 0.5s ease-out;' : 'mobile-active-hide 0.5s ease-out;', }}>
    //                 <TopBarShortCutLink href='/homepagetest' isActive={window.location.pathname == '/homepagetest'}>
    //                     <FaHome style={{ marginRight: 10, }} />
    //                     <span>Home</span>
    //                 </TopBarShortCutLink>
    //                 <TopBarShortCutLink isActive={window.location.pathname == '/null'}>
    //                     <span>item2</span>
    //                 </TopBarShortCutLink>
    //                 <TopBarShortCutLink isActive={window.location.pathname == '/null'}>
    //                     <span>item3</span>
    //                 </TopBarShortCutLink>
    //                 <TopBarShortCutLink isActive={window.location.pathname == '/null'}>
    //                     <span>item4</span>
    //                 </TopBarShortCutLink>
    //                 <TopBarShortCutLink isActive={window.location.pathname == '/null'}>
    //                     <span>item5</span>
    //                 </TopBarShortCutLink>
    //             </div>
    //             <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: 150, }}>
    //                 <a style={{ display: "flex", alignItems: "center",  cursor: "pointer", opacity: 0.5, }}>
    //                     {profile.avatar && <img src={getAvatarURL(profile)} alt={profile.username} width={40} />}
    //                     {!profile.avatar && <img src='https://cdn.discordapp.com/embed/avatars/0.png' alt={profile.username} width={40}/>}
    //                 </a>
    //                 <div className="profile" style={{ marginRight: 20, }}/>
    //                 <div className="hamburger profile" onClick={toggleMenu} style={{ alignItems: "center", }}>
    //                     <FaBars color="white" size={20} />
    //                 </div>
    //             </div>
    //         </div>
    //         </div>
    //     </div>
    //     )
    // }

    return (
        <div style={{ position: "fixed", display: "flex", alignItems: "center", justifyContent: "space-between", background: "#171717e0", boxShadow: "0 2px 10px 0 rgba(23, 23, 23, 0.88)", top: 0, zIndex: 100, width: "100%", height: 60, maxHeight: "40%", }} >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: 20, marginRight: 20, width: "100%", }} >
                <a href="/" style={{ color: "white", textDecoration: "none", width: 150, fontSize: 20, display: "flex", alignItems: "center", }}>
                    <img src="https://assets.camming.xyz/photos/logo/logo2.png" alt="logo" width={40} style={{ borderRadius: 5, marginRight: 15, }} />
                    <span>Camming</span>
                </a>

                {/* <div className={`topBarShortCutLink ${menuActive ? 'topBarShortCutLinkHamburger mobile-active' : ''}`} style={{ width: "40%", justifyContent: "space-between", animation: menuActive ? 'mobile-active-show 0.5s ease-out;' : 'mobile-active-hide 0.5s ease-out;', }}>
                    {mapShortCut.map((shortCut)=>{
                        return <TopBarShortCutLink onClick={() => navigate(`/${shortCut.path != 'home' ? shortCut.path : ''}`)} isActive={shortCut.path == 'home' ? window.location.pathname == '/' : window.location.pathname.startsWith(`/${shortCut.path}`)}>
                            {shortCut.icon}
                            <span>{shortCut.name}</span>
                        </TopBarShortCutLink>
                    })}
                </div> */}

                <div className="topBarShortCutLink">
                    <div>
                        {mapShortCut.flat().filter((shortCut) => !shortCut.onlyHamburger).map((shortCut) => (
                                <TopBarShortCutLink
                                    key={shortCut.path}
                                    onClick={() => navigate(`/${shortCut.path !== 'home' ? shortCut.path : ''}`)}
                                    isActive={shortCut.path === 'home' ? window.location.pathname === '/' : window.location.pathname.startsWith(`/${shortCut.path}`)}
                                >
                                    {shortCut.icon}
                                    <span>{shortCut.name}</span>
                                </TopBarShortCutLink>
                        ))}
                    </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: 150, }}>
                    {profile ? (
                        <a className="hamburger barshortcut" onClick={toggleMenu} style={{ display: "flex", alignItems: "center", cursor: "pointer", }}>
                            {profile.avatar ? (
                            <img src={getAvatarURL(profile)} alt={profile.username} width={40} style={{ borderRadius: "50%", }} />
                            ) : (
                            <img src='https://cdn.discordapp.com/embed/avatars/0.png' alt={profile.username} width={40} style={{ borderRadius: "50%", }} />
                            )}
                        </a>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                            <a onClick={() => {
                                if (window.location.hostname == 'camming.xyz') {window.location.href = 'https://camming.xyz/api/auth/login'}
                                else {window.location.href = 'http://210.246.215.139:3001/auth/v2/login'}
                            }} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", backgroundColor: "#5973d4", width: 100, height: 40, borderRadius: 5, }}>
                                Login
                            </a>

                            <div className="barshortcut" style={{ marginRight: 20, }}/>
                            <div className="hamburger barshortcut" onClick={toggleMenu} style={{ alignItems: "center", }}>
                                <FaBars color="white" size={20} />
                            </div>
                        </div>
                    )}
                </div>

                <div className={`hamburgerMenu ${menuActive ? 'active' : ''}`} id="menuBurgerBar" style={{ animation: menuActive ? 'fadeIn 0.5s ease-out;' : 'fadeOut 0.5s ease-out;' }}>
                    {mapShortCut.map((category, index) => (
                        <div key={index}>
                            {category.map((shortCut) => (
                                <TopBarShortCutLink 
                                    key={shortCut.path}
                                    onClick={() => navigate(`/${shortCut.path != 'home' ? shortCut.path : ''}`)}
                                    isActive={shortCut.path == 'home' ? window.location.pathname == '/' : window.location.pathname.startsWith(`/${shortCut.path}`)}
                                    style={{ fontSize: 20, }}
                                >
                                    {shortCut.icon}
                                    <span>{shortCut.name}</span>
                                </TopBarShortCutLink>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};