import styled, { css } from "styled-components";

export const BackgroundFixed = styled.div`
  position: fixed;
  z-index: -50;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(160deg, rgb(197 0 0) 0%, rgb(61 0 0) 40%, rgb(0 0 0) 100%);
`;

export const UserMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background: linear-gradient(160deg, rgb(158, 0, 0) 0%, rgb(61, 0, 0) 40%, rgb(0, 0, 0) 100%);
  border-radius: 5px;
  border: 1px solid #980000;
  margin: 8px 0;
  transition: 0.2s;

  & > img {
  border-radius: 50%;
  transition: 0.2s;
  }

  & > div {
    border-radius: 50%;
    transition: 0.2s;
    }
  
  &:hover {
    background: linear-gradient(160deg, rgb(158, 0, 0) 0%, rgb(61, 0, 0) 60%, rgb(0, 0, 0) 100%);
    border-color: #fff;
    cursor: pointer;
    & > img {
    border-radius: 10%;
    }
    & > div {
    border-radius: 10%;
    }
  }
`

export const GuildMenuItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background: linear-gradient(160deg, rgb(158, 0, 0) 0%, rgb(61, 0, 0) 40%, rgb(0, 0, 0) 100%);
  border-radius: 5px;
  border: 1px solid #980000;
  margin: 8px 0;
  transition: 0.2s;

  & > img {
  border-radius: 50%;
  transition: 0.2s;
  }

  & > div {
    border-radius: 50%;
    transition: 0.2s;
    }
  
  &:hover {
    background: linear-gradient(160deg, rgb(158, 0, 0) 0%, rgb(61, 0, 0) 60%, rgb(0, 0, 0) 100%);
    border-color: #fff;
    cursor: pointer;
    & > img {
    border-radius: 10%;
    }
    & > div {
    border-radius: 10%;
    }
  }
`;

export const GuildMenuItemStyleLoading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background: linear-gradient(160deg, rgb(158, 0, 0) 0%, rgb(61, 0, 0) 40%, rgb(0, 0, 0) 100%);
  border-radius: 5px;
  border: 1px solid #980000;
  margin: 8px 0;

  & > div {
    background-color: rgba(255, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  & > p {
    background-color: rgba(255, 0, 0, 0.5);
    border-radius: 5px;
    height: 25px;
    width: 30%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const GuildMenuCreateData = styled.div<{ isShow: boolean }>`
  position: fixed;
  display: ${({ isShow }) => isShow ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  width: 100%;
  animation: ${({ isShow }) => isShow ? 'guildMenuCreateDataShow' : ''} 0.5s forwards;
  @keyframes guildMenuCreateDataShow {
    from { background-color: #00000000; backdrop-filter: blur(0px); }
    to { background-color: #00000080; backdrop-filter: blur(2px); }
  }

  & > div {
    min-width: 30%;
    text-align: center;
    border-radius: 20px;
    border: #ff808080 2px solid;
    padding: 50px;
    background: linear-gradient(160deg, rgb(197 0 0) 0%, rgb(61 0 0) 60%, rgb(0 0 0) 100%);
    animation: ${({ isShow }) => isShow ? 'guildMenuCreateDataBoxShow' : ''} 0.5s forwards cubic-bezier(0, 1.3, 0.5, 1);
    @keyframes guildMenuCreateDataBoxShow {
      from { opacity: 0; transform: scale(0); }
      to { opacity: 1; transform: scale(1); }
    }
  }
`;

export const Container = styled.div`
  width: 60%;
  margin: 0 auto;
  color: #fff;
  font-family: "Kanit";
`;

export const AppBarStyle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 35px;
  box-sizing: border-box;
  border-bottom: 1px solid #8f8f8f;
`;

export const Title = styled.p`
  font-size: 22px;
`;

export const InputField = styled.input`
  padding: 5px 16px;
  box-sizing: border-box;
  font-size: 20px;
  color: #fff;
  font-family: "Kanit", sans-serif;
  background-color: #851f1f;
  border-radius: 5px;
  border: 5px solid #9d2f2f;
  transition: 0.2s;
  outline: 1px solid #ffffff00;
  width: 100%;
  &:focus {
    outline: 1px solid #ffffff99;
  }
  &::placeholder {
    color: #a6a6a6;
  }
`;

export const Select = styled.select`
  padding: 5px 16px;
  margin-top: 5px;
  box-sizing: border-box;
  font-size: 20px;
  color: #fff;
  font-family: "Kanit", sans-serif;
  background-color: #851f1f;
  border-radius: 5px;
  border: 5px solid #9d2f2f;
  transition: 0.2s;
  outline: 1px solid #ffffff00;
  width: 100%;
  cursor: pointer;
  &:focus {
    outline: 1px solid #ffffff99;
  }

  & > option {
    cursor: pointer;
    &:checked {
      background-color: #ad3f3f;
    }
  }
`;

export const TextArea = styled.textarea`
  padding: 14px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  font-family: "Kanit", sans-serif;
  background-color: #353535;
  border-radius: 5px;
  border: 1px solid #3f3f3f;
  outline: none;
  width: 100%;
  height: 120px;
  resize: none;
  :focus {
    outline: 1px solid #ffffff5a;
  }
`;

type ButtonProps = {
  variant: 'primary' | 'secondary';
};

export const Button = styled.button<ButtonProps>`
  padding: 10px 24px;
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Kanit", sans-serif;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.2s;
  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background-color: #4289ff;

      &:hover {
        background-color: #1c71ff;
      }
    `}
  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background-color: #3d3d3d;

      &:hover {
        background-color: #282828;
      }
    `}
`;

export const Login = styled.div`
  background-color: rgb(89, 115, 212);
  border-radius: 10px;
  box-shadow: 5px 5px 8px 0px #00000080;
  cursor: pointer;
  transition: 0.2s;

  & > div{
    display: flex;
    align-items: center;
    justify-content: center;
    & > span{
      position: absolute;
      background-color: rgb(70, 0, 0);
      border: 1px solid rgb(61, 0, 0);
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 10;
      opacity: 0;
      transform: translateY(-20px);
      transition: 0.2s;
    }
  }

  &:hover {
    background-color: rgb(70, 102, 221);
    
    & > div{
      display: flex;
      align-items: center;
      justify-content: center;
      & > span{
        position: absolute;
        background-color: rgb(70, 0, 0);
        border: 1px solid rgb(61, 0, 0);
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 10;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`;

export const Login2 = styled.div`
  background-color: rgb(65, 65, 65);
  border-radius: 10px;
  box-shadow: 5px 5px 8px 0px #00000080;
  transition: 0.2s;

  &:hover {
  background-color: rgb(51, 51, 51);
  }
`;

// export const PopupLoginClose = styled.span`
//   display: none;
//   position: absolute;
//   background-color: white;
//   border: 1px solid #ccc;
//   padding: 10px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   z-index: 1000;
// `;

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgb(59, 59, 59);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SlideOut = styled.div`
  background-color: rgb(59, 59, 59);
  animation: slideOut 1s forwards;

  @keyframes slideOut {
    0% { transform: translateY(0); }
    100% { transform: translateY(-100%); }
  }
`;

export const FadeAway = styled.div`
  animation: fadeAway 0.4s forwards;

  @keyframes fadeAway {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

export const NotFoundImg = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 40%;
  animation: NotFoundImg 4s;

  @keyframes NotFoundImg {
    0% { opacity: 0; top: -50%; }
    50% { opacity: 0; top: -50%; }
    65% { opacity: 0.5; top: 5%; }
    100% { opacity: 1; top: 0%; }
  }
`;

export const NotFoundText = styled.div`
  animation: NotFoundText 5s forwards;

  @keyframes NotFoundText {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

export const ShowPopupLoadingErrorBox = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 5%;
  width: 100%;
  animation: PopupBottom 1s forwards cubic-bezier(0, 0, 0, 1);
`;

export const ShowPopupLoadingError = styled.p`
  text-align: center;
  width: 70%;
  background: rgba(199, 0, 0, 0.75);
  padding: 10px;
  border: 1px solid rgb(31, 31, 31);
  border-radius: 5px;
`;

export const TopBarShortCutLink = styled.a<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.isActive ? 'rgb(225, 100, 100)' : '#fff'};
  margin: 0 5px;
  transition: 0.2s;

  @keyframes Zoom-in-out {
    0% { transform: scale(1); }
    50% { transform: scale(1.3); }
    100% { transform: scale(1); }
  }

  & > svg{
    transform: scale(1);
    transition: 0.5s;
    margin-right: 10px;
  }

  &:hover {
    color: rgb(225, 100, 100);
    & > svg{
      color: #ffa1a1;
      transform: scale(1.2);
    }
  }
`;

export const ItemBarInfoHomePage = styled.button<{ isSelect: boolean }>`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(226, 101, 101, 0);
  padding: 10px;
  cursor: pointer;
  color: ${props => props.isSelect ? 'rgb(225, 100, 100)' : '#fff'};
  transition: 0.2s;

  @keyframes Zoom-in-out {
    0% { transform: scale(1); }
    50% { transform: scale(1.3); }
    100% { transform: scale(1); }
  }

  & > svg{
    transform: scale(1);
    margin-right: 5px;
    transition: 0.5s;
  }

  &:hover {
    color: rgb(225, 100, 100);
    border-bottom: 1px solid rgb(225, 100, 100);
    & > svg{
      color: #ffa1a1;
      transform: scale(1.3);
    }
  }
`;

export const ItemBarInfoHomePageDisable = styled.button<{ isSelect: boolean }>`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(226, 101, 101, 0);
  padding: 10px;
  cursor: no-drop;
  color: gray;
  transition: 0.2s;

  @keyframes Zoom-in-out {
    0% { transform: scale(1); }
    50% { transform: scale(1.3); }
    100% { transform: scale(1); }
  }

  & > svg{
    transform: scale(1);
    margin-right: 5px;
    transition: 0.5s;
  }
`;

export const BarInfoHomePage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);

  @media (max-width: 500px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const DevBoxHomePage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgb(210, 48, 48);
  background-position: center center;
  box-shadow: black 4px 7px 20px 0px;
  width: 220px;
  height: 320px;
  border-radius: 10px;
  margin: 10px;
  overflow: hidden;
  transform: scale(1);
  transition: 0.5s;

  & > div {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 10px;
  }

  & > p {
    position: absolute;
    z-index: -1;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 10px;
    filter: blur(1px);
    clip-path: inset(0);
    transition: 0.5s;
  }

  &:hover {
    transform: scale(1.1);

    & > p {
      filter: blur(0px);
    }
  }
`;

export const HomePage1Button1 = styled.button`
  background: hsl(356, 73%, 58%);
  color: black;
  border: rgb(255 90 90) 1px solid;
  padding: 10px;
  font-size: 20px;
  font-family: Kanit;
  min-width: 130px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background: rgb(255, 153, 160);
  }
`;

export const HomePage1Button2 = styled.button`
background: rgba(0, 0, 0, 0);
color: rgb(255, 255, 255);
border: 1px solid rgb(255, 90, 90);
padding: 10px;
font-size: 20px;
font-family: Kanit;
min-width: 130px;
border-radius: 4px;
cursor: pointer;
transition: 0.5s;

  &:hover {
    background: rgba(255, 90, 90, 0.5);
  }
`;

export const ContactPage1 = styled.div`
    margin: 10px;
    background: linear-gradient(160deg, rgb(197 0 0) 0%, rgb(61 0 0) 70%, rgb(0 0 0) 100%);
    border-radius: 10px;
    box-shadow: black 4px 7px 20px 0px;
    width: min-content;
    display: flex;
    flex-direction: row;
`;

export const DashCategoryMenu = styled.div<{ isShow: boolean }>`

  @keyframes dashSidebarShow {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(90deg); }
  }
  @keyframes dashSidebarHide {
    0% { transform: rotate(90deg); }
    100% { transform: rotate(0deg); }
  }

  & > #sidebar {
    display: flex;
    color: #ffb8b8;
    cursor: pointer;
    font-weight: bold;
    transition: 0.3s;

    & > #arrow {
      animation: ${({ isShow }) => isShow ? 'dashSidebarShow' : 'dashSidebarHide'} 0.3s forwards;
    }
    & > #name {
      padding-left: 5px;
    }
    &:hover {
      color: #ffffff;
    }
  }
  & > #sub-menu {
    overflow: hidden;
    transition: 0.3s;
    padding-left: 10px;
    margin: 5px;
    opacity: ${({ isShow }) => (isShow ? '1' : '0')};
    height: ${({ isShow }) => (isShow ? 'auto' : '0')};

    & > div {
      transition: transform 0.3s;
      transform: ${({ isShow }) => isShow ? 'translateY(0%)' : 'translateY(-100%)'};

      & > div {
        display: flex;
        align-items: center;
        padding: 5px;
        margin: 5px;
        cursor: pointer;
        border-radius: 10px;
        transition: 0.3s;
        
        &:hover {
          background-color: #ff47474d;
        }
        & > img {
          margin: 0px 10px 0px 5px;
        }
      }
    }
  }
`;

export const DashPageServerGeneralInfo = styled.div`
  background-color: #ff4d4d4d;
  padding: 20px;
  border-radius: 10px;

  & > div {
    // background-color: #ff4d4d4d;
    padding: 5px 20px;
    margin: 5px;
    border-radius: 10px;
    border: #ff4d4d4d 3px solid;
  }
`;

export const DashPageServerGeneralSettings = styled.div`
  margin: 20px;
`;

export const ChangePopup = styled.div<{ isShow: boolean | null }>`
  display: ${({ isShow }) => isShow === null ? 'none' : 'flex'};
  justify-content: center;

  & > div {
    position: fixed;
    z-index: 2;
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    background-color: #9b2828;
    transition: 0.5s;
    animation: ${({ isShow }) => isShow ? 'dashChangePopupShow cubic-bezier(0.3, 1.5, 0.5, 1)' : 'dashChangePopupHide cubic-bezier(0.5, 0, 0.7, -0.5)'} 1s forwards;

    & > div {
      padding: 0px 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      & > div {
        cursor: pointer;
        margin: 0px 10px;
        padding: 10px;
        border-radius: 10px;
        background-color: #d04444;
      }
    }
  }

  @keyframes dashChangePopupShow {
    0% { opacity: 0; display: flex; }
    10% { opacity: 0; transform: translateY(100%); }
    100% { opacity: 1; transform: translateY(-100%); }
  }
  @keyframes dashChangePopupHide {
    0% { opacity: 1; transform: translateY(-100%); }
    90% { opacity: 0; transform: translateY(100%); }
    100% { opacity: 0; display: none; }
  }
`;

export const PopupStatusSaving = styled.div<{ isShow: boolean | null }>`
  display: ${({ isShow }) => isShow === null ? 'none' : 'flex'};
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: ${({ isShow }) => isShow ? 'dashPopupSavingShow' : 'dashPopupSavingHide'} 0.5s forwards;

  & > div {
    padding: 50px;
    border-radius: 30px;
    background-color: #9b2828;
    transition: 0.2s;
    animation: ${({ isShow }) => isShow ? 'dashPopupSavingBoxShow cubic-bezier(0.3, 1.5, 0.5, 1)' : 'dashPopupSavingBoxHide cubic-bezier(0.5, 0, 0.7, -0.5)'} 0.3s forwards;
  }

  @keyframes dashPopupSavingShow {
    0% { z-index: 3; }
    10% { z-index: 3; backdrop-filter: blur(0px); background-color: #00000000; }
    100% { z-index: 3; backdrop-filter: blur(3px); background-color: #00000066; }
  }
  @keyframes dashPopupSavingHide {
    0% { z-index: 3; backdrop-filter: blur(3px); background-color: #00000066; }
    90% { z-index: 3; backdrop-filter: blur(0px); background-color: #00000000; }
    100% { z-index: -1; }
  }

  @keyframes dashPopupSavingBoxShow {
    from { opacity: 0; transform: scale(0); }
    to { opacity: 1; transform: scale(1); }
  }
  @keyframes dashPopupSavingBoxHide {
    from { opacity: 1; transform: scale(1); }
    to { opacity: 0; transform: scale(0); }
  }
`;